import { useRouter } from "next/router";

import { eventPath } from "@/constants/app-paths";

export const useEventList = () => {
  const router = useRouter();

  const onClickKenbanButton = () => router.push(`/${router.locale}${eventPath(process.env.KENBAN_EVENT_ID || "")}`);
  const onClickItokawaButton = () => router.push(`/${router.locale}${eventPath(process.env.ITOKAWA_EVENT_ID || "")}`);
  const onClickKiunkakuButton = () => router.push(`/${router.locale}${eventPath(process.env.KIUNKAKU_EVENT_ID || "")}`);

  return {
    onClickKenbanButton,
    onClickItokawaButton,
    onClickKiunkakuButton,
  };
};
