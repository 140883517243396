import { css } from "@emotion/react";
import React, { forwardRef } from "react";

import { Grid } from "@mui/material";

import { useLocale } from "@/custom-hooks/common/useLocale";
import { appTypo } from "@/styles/app-typography";
import { mqMinScreen } from "@/utils/mediaQuery";

const Blog = forwardRef<HTMLDivElement>(function Blog(props, ref) {
  const { translated } = useLocale();
  return (
    <Grid ref={ref} css={rootStyled}>
      <Grid css={textStyled}>
        {translated.PAGE_SPECIAL_FEATURE.SPACIAL_FEATURE_TITLE1}
        <wbr />
        {translated.PAGE_SPECIAL_FEATURE.SPACIAL_FEATURE_TITLE2}
        <br />
        {translated.PAGE_SPECIAL_FEATURE.SPACIAL_FEATURE_TITLE3}
      </Grid>
      <Grid css={subTextStyled}>{translated.PAGE_SPECIAL_FEATURE.SPACIAL_FEATURE_TEXT}</Grid>
    </Grid>
  );
});

export default React.memo(Blog);

const rootStyled = css`
  padding: 0 16px 48px 16px;
  ${mqMinScreen("lg")} {
    padding: 0 0 48px 0;
  }
`;

const textStyled = css`
  font-family: ${appTypo.font.kintoSans};
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 1.5;
  margin-bottom: 18px;
  word-break: keep-all;
  overflow-wrap: anywhere;
  ${mqMinScreen("sm")} {
    text-align: center;
  }
`;

const subTextStyled = css`
  font-family: ${appTypo.font.kintoSans};
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  ${mqMinScreen("lg")} {
  }
  white-space: pre-line;
`;
