import { css } from "@emotion/react";
import React from "react";

import { Grid } from "@mui/material";

import { useLocale } from "@/custom-hooks/common/useLocale";
import { useEventList } from "@/custom-hooks/page-special-feature/useEventList";
import { appColor } from "@/styles/app-colors";
import { appTypo } from "@/styles/app-typography";
import { mqMinScreen } from "@/utils/mediaQuery";

import EventListItem from "../organisms/EventListItem";

const EventList: React.FC = () => {
  const { translated } = useLocale();
  const { onClickKenbanButton, onClickItokawaButton, onClickKiunkakuButton } = useEventList();

  return (
    <Grid css={rootStyled}>
      <Grid css={eventListStyled}>
        <Grid mb={1} css={textRecommendationPointStyled}>
          {translated.PAGE_SPECIAL_FEATURE.EVENT_LIST}
        </Grid>
        <Grid css={recommendationListStyled}>
          <EventListItem
            imageUrl="/assets/atami_yozakura_festival7.png"
            title={translated.PAGE_SPECIAL_FEATURE.TITLE_EVENT_LIST1}
            subTitle={translated.PAGE_SPECIAL_FEATURE.SUBTITLE_EVENT_LIST1}
            onClick={onClickKenbanButton}
          />
          <EventListItem
            imageUrl="/assets/recommendation_point1.png"
            title={translated.PAGE_SPECIAL_FEATURE.TITLE_EVENT_LIST2}
            subTitle={translated.PAGE_SPECIAL_FEATURE.SUBTITLE_EVENT_LIST2}
            onClick={onClickItokawaButton}
          />
          <EventListItem
            imageUrl="/assets/atami_yozakura_festival6.png"
            title={translated.PAGE_SPECIAL_FEATURE.TITLE_EVENT_LIST3}
            subTitle={translated.PAGE_SPECIAL_FEATURE.SUBTITLE_EVENT_LIST3}
            onClick={onClickKiunkakuButton}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

const rootStyled = css`
  width: 100vw;
  padding: 0px 16px;
  ${mqMinScreen("lg")} {
    max-width: 1120px;
    padding: 0px;
  }
`;

const eventListStyled = css`
  display: flex;
  padding-bottom: 48px;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  align-self: stretch;
`;

const textRecommendationPointStyled = css`
  width: 100%;
  font-family: ${appTypo.font.kintoSans};
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  line-height: 1.5;
  color: ${appColor.Text.primary};
  ${mqMinScreen("sm")} {
    text-align: center;
  }
`;

const recommendationListStyled = css`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
  ${mqMinScreen("lg")} {
    flex-direction: row;
    justify-content: space-between;
  }
`;

export default React.memo(EventList);
