import { css } from "@emotion/react";
import React from "react";

import { Grid, useMediaQuery, useTheme } from "@mui/material";

import { appColor } from "@/styles/app-colors";
import { appTypo } from "@/styles/app-typography";
import { mqMinScreen } from "@/utils/mediaQuery";

import Access from "../organisms/Access";

import GoogleMapContainer from "@/components/common/GoogleMapContainer";
import { latSakurazaka, lngSakurazaka } from "@/constants/atami";

type Props = {
  titleMap: string;
  labelAccess: string;
  accessFromTokyo: string;
  accessFromKyoto: string;
  accessFromNagoya: string;
  accessTo: string;
  destinationLine1: string;
  destinationLine2: string;
  travelTime1: string;
  travelTime2: string;
  travelTime3: string;
};

const Map: React.FC<Props> = (props) => {
  const theme = useTheme();
  const isMobile = !useMediaQuery(theme.breakpoints.up("lg"));

  return (
    <Grid css={rootStyled}>
      <Grid css={mapInfoStyled}>
        <Grid mb={4}>
          <Grid css={textMapStyled}>{props.titleMap}</Grid>
        </Grid>
      </Grid>
      <Grid mb={3}>
        <GoogleMapContainer lat={latSakurazaka} lng={lngSakurazaka} />
      </Grid>
      <Grid px={isMobile ? 2 : 0}>
        <Access
          labelAccess={props.labelAccess}
          accessFromTokyo={props.accessFromTokyo}
          accessFromKyoto={props.accessFromKyoto}
          accessFromNagoya={props.accessFromNagoya}
          accessTo={props.accessTo}
          destinationLine1={props.destinationLine1}
          destinationLine2={props.destinationLine2}
          travelTime1={props.travelTime1}
          travelTime2={props.travelTime2}
          travelTime3={props.travelTime3}
        />
      </Grid>
    </Grid>
  );
};

export default React.memo(Map);

const rootStyled = css`
  width: 100%;
  border-top: 1px solid ${appColor.Border.grayLight};
  padding: 48px 0px;
  ${mqMinScreen("lg")} {
    width: 100%;
  }
`;

const mapInfoStyled = css`
  padding: 0px 16px;
  margin-bottom: 24px;
  ${mqMinScreen("lg")} {
    padding: 0;
  }
`;

const textMapStyled = css`
  font-family: ${appTypo.font.kintoSans};
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  line-height: 1.5;
  color: ${appColor.Text.primary};
  ${mqMinScreen("sm")} {
    width: 100%;
    text-align: center;
  }
`;
