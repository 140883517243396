import React from "react";

import { useLocale } from "@/custom-hooks/common/useLocale";

import { GoogleMap, LoadScript, MarkerF } from "@react-google-maps/api";

type Props = {
  width?: string;
  height?: string;
  lat: number;
  lng: number;
};

const GoogleMapContainer: React.FC<Props> = (props) => {
  const { locale } = useLocale();
  const mapContainerStyle = {
    width: props.width ?? "100%",
    height: props.height ?? "480px",
  };
  const centerPin = { lat: props.lat, lng: props.lng };

  return (
    <LoadScript language={locale} googleMapsApiKey={process.env.GOOGLE_MAP_API_KEY || ""}>
      <GoogleMap mapContainerStyle={mapContainerStyle} center={centerPin} zoom={15}>
        <MarkerF position={centerPin} />
      </GoogleMap>
    </LoadScript>
  );
};

export default React.memo(GoogleMapContainer);
