import { css } from "@emotion/react";
import React from "react";

import { Grid } from "@mui/material";

import { appColor } from "@/styles/app-colors";
import { appTypo } from "@/styles/app-typography";
import { mqMinScreen } from "@/utils/mediaQuery";

import AccessItem from "../molucules/AccesItem";

type Props = {
  labelAccess: string;
  accessFromTokyo: string;
  accessFromKyoto: string;
  accessFromNagoya: string;
  accessTo: string;
  destinationLine1: string;
  destinationLine2: string;
  travelTime1: string;
  travelTime2: string;
  travelTime3: string;
};

const Access: React.FC<Props> = (props) => {
  return (
    <Grid css={rootStyled}>
      <Grid mb={3}>
        <Grid css={textAccessStyled}>{props.labelAccess}</Grid>
      </Grid>
      <Grid css={accessLinesStyled}>
        <Grid css={accessLineLeftStyled}>
          <AccessItem accessFrom={props.accessFromTokyo} line={props.destinationLine1} travelTime={props.travelTime1} />
          <AccessItem accessFrom={props.accessFromKyoto} line={props.destinationLine2} travelTime={props.travelTime2} />
          <AccessItem
            accessFrom={props.accessFromNagoya}
            line={props.destinationLine2}
            travelTime={props.travelTime3}
          />
        </Grid>
        <Grid css={accessToStyled}>
          <Grid css={textAccessToStyled}>{props.accessTo}</Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default React.memo(Access);

const rootStyled = css`
  width: 100%;
  ${mqMinScreen("lg")} {
    max-width: 1120px;
    width: 100%;
  }
`;

const textAccessStyled = css`
  font-family: ${appTypo.font.kintoSans};
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: ${appColor.Text.primary};
  padding-bottom: 8px;
  ${mqMinScreen("sm")} {
    text-align: center;
  }
  ${mqMinScreen("lg")} {
    text-align: left;
  }
`;

const accessLinesStyled = css`
  display: flex;
  width: 100%;
  flex-direction: column;
  ${mqMinScreen("lg")} {
    flex-direction: row;
  }
`;

const accessLineLeftStyled = css`
  width: 100%;
  display: flex;
  gap: 24px;
  flex-direction: row;
  ${mqMinScreen("lg")} {
    flex-direction: column;
  }
`;

const accessToStyled = css`
  display: flex;
  width: 100%;
  height: 40px;
  align-items: center;
  justify-content: center;
  border: 1px solid ${appColor.Border.grayLight};
  ${mqMinScreen("lg")} {
    min-width: 160px;
    flex-basis: 160px;
    height: 192px;
  }
`;

const textAccessToStyled = css`
  font-family: ${appTypo.font.kintoSans};
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: ${appColor.Text.black};
`;
