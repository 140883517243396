import React, { useState } from "react";

import { Grid, Skeleton, css } from "@mui/material";

type Props = JSX.IntrinsicElements["img"];

const ImageWithSkeleton: React.FC<Props> = (props) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const onLoad = () => setIsLoaded(true);

  return (
    <Grid css={rootStyled}>
      {/* eslint-disable-next-line @next/next/no-img-element */}
      <img {...props} alt={props.alt ?? ""} onLoad={onLoad} css={imageStyled(isLoaded)} />
      <Skeleton css={SkeletonStyled(isLoaded)} variant="rectangular" width="100%" height="100%" animation="wave" />
    </Grid>
  );
};

export default React.memo(ImageWithSkeleton);

const rootStyled = css`
  position: relative;
  width: 100%;
  height: 100%;
`;

const imageStyled = (isLoaded: boolean) => css`
  opacity: ${isLoaded ? 1 : 0};
  transition: opacity 0.3s ease-in-out;
`;

const SkeletonStyled = (isLoaded: boolean) => css`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: ${isLoaded ? 0 : 1};
  visibility: ${isLoaded ? "hidden" : "visible"};
  transition: 0.3s ease-in-out;
`;
