import { css } from "@emotion/react";
import React from "react";

import { Grid } from "@mui/material";

import { appColor } from "@/styles/app-colors";
import { appTypo } from "@/styles/app-typography";
import { mqMinScreen } from "@/utils/mediaQuery";

import { SvgIconAccessLine } from "@/svgs";

type Props = {
  accessFrom: string;
  line: string;
  travelTime: string;
};

const AccessItem: React.FC<Props> = (props) => {
  return (
    <Grid css={rootStyled}>
      <Grid css={accessFromStyled}>
        <Grid css={textAccessFromStyled}>{props.accessFrom}</Grid>
      </Grid>
      <Grid css={lineContainerStyled}>
        <Grid css={rotateContainerStyled}>
          <Grid css={textStyled}>{props.line}</Grid>
          <Grid css={iconLineContainer}>
            <SvgIconAccessLine />
          </Grid>
          <Grid css={textStyled}>{props.travelTime}</Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default React.memo(AccessItem);

const rootStyled = css`
  display: flex;
  height: 350px;
  flex-direction: column;
  align-items: center;
  ${mqMinScreen("lg")} {
    height: 100%;
    flex-direction: row;
    align-items: center;
  }
`;

const accessFromStyled = css`
  display: flex;
  width: calc((100vw - 32px - 48px) / 3);
  min-height: 48px;
  flex-basis: 48px;
  align-items: center;
  justify-content: center;
  background-color: ${appColor.Background.brownLight};
  ${mqMinScreen("lg")} {
    min-width: 160px;
    flex-basis: 160px;
  }
`;

const textAccessFromStyled = css`
  font-family: ${appTypo.font.kintoSans};
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: ${appColor.Text.black};
`;

const lineContainerStyled = css`
  display: flex;
  width: 60px;
  height: 400px;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  ${mqMinScreen("lg")} {
    align-items: center;
    flex: 1 0 0;
    height: 100%;
    overflow: hidden;
  }
`;

const rotateContainerStyled = css`
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 3px;
  transform: rotate(-90deg);
  ${mqMinScreen("lg")} {
    transform: rotate(0deg);
    width: 100%;
    height: 48px;
    align-items: center;
  }
`;

const textStyled = css`
  font-family: ${appTypo.font.kintoSans};
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 12px;
  color: ${appColor.Text.primary};
  width: 280px;
  text-align: center;
  line-break: strict;
  ${mqMinScreen("lg")} {
    width: 100%;
  }
`;

const iconLineContainer = css`
  height: 16px;
`;
