import { css } from "@emotion/react";
import React from "react";

import { Grid, useMediaQuery, useTheme } from "@mui/material";

import topPcImg from "../../../../../public/assets/top_pc.webp";
import topSpImg from "../../../../../public/assets/top_sp.webp";

import ImageWithSkeleton from "@/components/common/image/ImageWithSkeleton";
import { headerHeightAtom } from "@/global-states/common-atoms";
import { useAtomValue } from "jotai";

type Props = {
  scrollTo: () => void;
};

const TopImage: React.FC<Props> = (props) => {
  const headerHeight = useAtomValue(headerHeightAtom);
  const theme = useTheme();
  const isMobile = !useMediaQuery(theme.breakpoints.up("sm"));

  return (
    <Grid css={rootStyled(headerHeight)}>
      <ImageWithSkeleton
        alt="atami_yozakura_festival"
        src={isMobile ? topSpImg.src : topPcImg.src}
        style={{
          width: "auto",
          maxWidth: "100%",
          height: "auto",
          maxHeight: `calc(100svh - ${headerHeight}px)`,
          objectFit: "contain",
        }}
        width={2000}
        height={2000}
        loading="eager"
      />
    </Grid>
  );
};

export default React.memo(TopImage);

const rootStyled = (headerHeight: number) => css`
  width: 100%;
  max-height: calc(100svh - ${headerHeight}px);
  position: relative;
  text-align: center;
`;
