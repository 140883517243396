import { css } from "@emotion/react";
import React, { useRef } from "react";

import { Grid } from "@mui/material";

import { useLocale } from "@/custom-hooks/common/useLocale";
import { appColor } from "@/styles/app-colors";

import { BaseLayout } from "../../common/layout/BaseLayout";
import EventList from "./container/EventList";
import Map from "./container/Map";
import Blog from "./molucules/Blog";
import TopImage from "./organisms/TopImage";

import { headerHeightAtom } from "@/global-states/common-atoms";
import { useAtomValue } from "jotai";

const SpecialFeatureTemplate: React.FC = () => {
  const { translated } = useLocale();
  const headerHeight = useAtomValue(headerHeightAtom);
  const blogRef = useRef<HTMLDivElement>(null);

  const scrollToBlog = () => {
    if (blogRef.current !== null) {
      window.scrollBy({ top: blogRef.current.getBoundingClientRect().top - headerHeight - 32, behavior: "smooth" });
    }
  };

  return (
    <BaseLayout pageTitle={translated.PAGE_SPECIAL_FEATURE.PAGE_TITLE} description={translated.COMMON.DESCRIPTION}>
      <TopImage scrollTo={scrollToBlog} />
      <Grid css={containerStyled}>
        <Blog ref={blogRef} />
        <EventList />
        <Map
          titleMap={translated.COMMON.ACCESS}
          labelAccess={translated.PAGE_TOP.ACCESS_BY_TRAIN}
          accessFromTokyo={translated.COMMON.TOKYO}
          accessFromKyoto={translated.COMMON.KYOTO}
          accessFromNagoya={translated.COMMON.NAGOYA}
          accessTo={translated.COMMON.ATAMI}
          destinationLine1={translated.PAGE_TOP.TOKAIDO_SANYO_SHINKANSEN_BOUND_FOR_SINOOSAKA}
          destinationLine2={translated.PAGE_TOP.TOKAIDO_SANYO_SHINKANSEN_BOUND_FOR_TOKYO}
          travelTime1={translated.PAGE_TOP.TRAFFIC_TIME1}
          travelTime2={translated.PAGE_TOP.TRAFFIC_TIME2}
          travelTime3={translated.PAGE_TOP.TRAFFIC_TIME3}
        />
      </Grid>
    </BaseLayout>
  );
};

export default React.memo(SpecialFeatureTemplate);

const containerStyled = css`
  display: flex;
  height: auto;
  flex-direction: column;
  align-items: center;
  padding: 64px 0 40px 0;
  background-color: ${appColor.Background.white};
  max-width: 1120px;
  margin: 0 auto;
`;
