import { css } from "@emotion/react";
import React from "react";

import { Button, Grid } from "@mui/material";

import { useLocale } from "@/custom-hooks/common/useLocale";
import { appColor } from "@/styles/app-colors";
import { appTypo } from "@/styles/app-typography";
import { mqMinScreen } from "@/utils/mediaQuery";

import ImageWithSkeleton from "@/components/common/image/ImageWithSkeleton";

type Props = {
  imageUrl: string;
  title: string;
  subTitle: string;
  onClick: React.MouseEventHandler<HTMLButtonElement> | undefined;
};

const EventListItem: React.FC<Props> = (props) => {
  const { translated } = useLocale();

  return (
    <Grid css={rootStyled}>
      <Grid css={imageListItemBox}>
        <Grid css={imageContainerStyled}>
          <ImageWithSkeleton
            css={imageStyled}
            alt="recommendation_point"
            src={props.imageUrl}
            loading="eager"
            style={{ width: "100%", height: "100%", objectFit: "cover" }}
            width={700}
            height={700}
          />
        </Grid>
        <Grid css={eventListItemBox}>
          <Grid>
            <Grid css={textTitleStyled}>{props.title}</Grid>
          </Grid>
          <Grid>
            <Grid css={textSubTitleStyled}>{props.subTitle}</Grid>
          </Grid>
          <Button css={buttonStyled} onClick={props.onClick}>
            {translated.PAGE_SPECIAL_FEATURE.EVENT_ON_CLICK}
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default React.memo(EventListItem);

const rootStyled = css`
  width: 100%;
  ${mqMinScreen("lg")} {
    width: calc((100% - 32px) / 3);
    height: auto;
  }
`;

const imageStyled = css`
  width: 100%;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  ${mqMinScreen("lg")} {
    height: 160px;
    width: 352px;
  }
`;

const imageListItemBox = css`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1 0 0;
  border-radius: 4px;
  background: ${appColor.Background.white};
  box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.12);
`;

const eventListItemBox = css`
  display: flex;
  padding: 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  align-self: stretch;
`;

const textTitleStyled = css`
  font-family: ${appTypo.font.kintoSans};
  color: ${appColor.Text.primary};
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%; /* 20px */
`;

const textSubTitleStyled = css`
  font-family: ${appTypo.font.kintoSans};
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: ${appColor.Text.primary};
`;

const buttonStyled = css`
  align-self: stretch;
  height: 48px;
  padding: 0px 16px;
  color: ${appColor.Text.white};
  text-align: center;
  font-family: ${appTypo.font.kintoSans};
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px; /* 125% */
  border-radius: 8px;
  background: ${appColor.Background.lightRed};
  &:hover {
    background: ${appColor.Background.lightRed};
    opacity: 0.7;
  }
`;

const imageContainerStyled = css`
  width: 100%;
  height: 160px;
  ${mqMinScreen("lg")} {
    height: 160px;
    width: 352px;
  }
`;
